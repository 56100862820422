<template>
  <content-wrapper :title="labels.LBL_COMPANY_DETAIL" back>
    <template slot="actions">
      <el-button size="small" type="success" @click="submitForm('configForm')">
        <i class="el-icon-check mr-1"></i>
        {{ labels.LBL_UPDATE }}
      </el-button>
    </template>

    <el-form
      ref="configForm"
      :model="organization"
      :rules="rules"
      label-position="top"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <div
            style="height: 200px; display:flex; justify-content: center; align-items: center"
          >
            <app-logo />
          </div>
        </el-col>

        <el-col :span="8">
          <el-form-item prop="name" :label="labels.LBL_COMPANY_NAME">
            <el-input v-model="organization.name" readonly></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item prop="establishment" :label="labels.LBL_ESTD_DATE">
            <el-input v-model="organization.establishment" readonly></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item prop="phone" :label="labels.LBL_CONTACT_NO">
            <el-input v-model="organization.phone"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item prop="email" :label="labels.LBL_EMAIL">
            <el-input v-model="organization.email"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item prop="address1" :label="labels.LBL_ADDRESS">
            <el-input v-model="organization.address1"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item :label="labels.LBL_ADDRESS_2">
            <el-input v-model="organization.address2"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item prop="city" :label="labels.LBL_CITY">
            <el-input v-model="organization.city"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item prop="state" :label="labels.LBL_STATE">
            <el-input v-model="organization.state"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item prop="postal" :label="labels.LBL_POSTAL">
            <el-input v-model="organization.postal"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item prop="online_url" label="Online Url">
            <el-input v-model="organization.online_url"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="16">
          <el-form-item prop="motto" :label="labels.LBL_COM_MOTTO">
            <el-input
              v-model="organization.motto"
              type="textarea"
              rows="3"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="Years Experienced">
            <el-input-number v-model.number="organization.years_experienced" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Industries Served">
            <el-input-number v-model="organization.industries_served" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Factories Built">
            <el-input-number v-model="organization.factories_built" />
          </el-form-item>
        </el-col>
      </el-row>

      <h3 class="my-2">{{ labels.LBL_SOCIAL_LINKS }}</h3>

      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item :label="labels.LBL_FB">
            <el-input v-model="organization.facebook_url"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item :label="labels.LBL_YT">
            <el-input v-model="organization.youtube_url"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item :label="labels.LBL_LNKIN">
            <el-input v-model="organization.linkedin_url"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item :label="labels.LBL_TWI">
            <el-input v-model="organization.twitter_url"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item :label="labels.LBL_INSTA">
            <el-input v-model="organization.instagram_url"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </content-wrapper>
</template>

<script>
  import { labels, rules } from "@/common";
  import { mapState } from "vuex";

  import ContentWrapper from "@/components/layouts/AdminContentWrapper";
  import AppLogo from "../../../components/AppLogo.vue";

  export default {
    components: {
      ContentWrapper,
      AppLogo,
    },

    data() {
      return {
        labels,
        rules,
      };
    },

    computed: {
      ...mapState({ organization: (state) => state.organization.orgDetail }),
    },

    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$store.dispatch(
              "organization/updateDetail",
              this.organization
            );
          }
        });
      },
    },
  };
</script>

<style></style>
